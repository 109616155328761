var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-form-inline",
                attrs: {
                  "label-position": "right",
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "120px"
                }
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "采购订单编号", prop: "code" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: 100,
                                placeholder: "请输入采购订单编号"
                              },
                              model: {
                                value: _vm.ruleForm.code,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "code", $$v)
                                },
                                expression: "ruleForm.code"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "供应商", prop: "supplierId" } },
                          [
                            _c(
                              "el-select",
                              {
                                directives: [
                                  {
                                    name: "selectScrollEnd",
                                    rawName: "v-selectScrollEnd",
                                    value: _vm.supplierCandidatesLoadMore,
                                    expression: "supplierCandidatesLoadMore"
                                  }
                                ],
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  "remote-method": _vm.remoteMethod,
                                  placeholder: "请选择供应商"
                                },
                                model: {
                                  value: _vm.ruleForm.supplierId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "supplierId", $$v)
                                  },
                                  expression: "ruleForm.supplierId"
                                }
                              },
                              _vm._l(_vm.supplierOptions, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.fullName,
                                    value: item.id
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("采购订单行")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "FunctionHeader",
              {
                ref: "functionHeader",
                staticStyle: { padding: "10px 0 10px 0" },
                attrs: { "hidden-search": true }
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "all" }, slot: "all" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "e-btn",
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.selectAdd }
                      },
                      [_vm._v("添加物料")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c("MTable", {
              ref: "mTable",
              attrs: {
                "only-key": "uuid",
                height: 400,
                "show-page": false,
                data: _vm.orderList,
                columns: _vm.columnsOrder
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "purchaseQuantity",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c("el-input-number", {
                          style: {
                            border: row.isWarningPurchaseQuantity
                              ? "1px solid #F56C6C"
                              : "",
                            borderRadius: "4px"
                          },
                          attrs: {
                            min: 0,
                            "controls-position": "right",
                            step: 0.01
                          },
                          on: {
                            blur: function(e) {
                              return _vm.onBlur(e, row, "purchaseQuantity")
                            }
                          },
                          model: {
                            value: row.purchaseQuantity,
                            callback: function($$v) {
                              _vm.$set(row, "purchaseQuantity", $$v)
                            },
                            expression: "row.purchaseQuantity"
                          }
                        })
                      ],
                      1
                    )
                  }
                },
                {
                  key: "wantReceiveTime",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c("el-date-picker", {
                          style: {
                            border: row.isWarningWantReceiveTime
                              ? "1px solid #F56C6C"
                              : "",
                            borderRadius: "4px"
                          },
                          attrs: {
                            type: "datetime",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            placeholder: "选择日期"
                          },
                          model: {
                            value: row.wantReceiveTime,
                            callback: function($$v) {
                              _vm.$set(row, "wantReceiveTime", $$v)
                            },
                            expression: "row.wantReceiveTime"
                          }
                        })
                      ],
                      1
                    )
                  }
                },
                {
                  key: "confirmReceiveTime",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            placeholder: "选择日期"
                          },
                          model: {
                            value: row.confirmReceiveTime,
                            callback: function($$v) {
                              _vm.$set(row, "confirmReceiveTime", $$v)
                            },
                            expression: "row.confirmReceiveTime"
                          }
                        })
                      ],
                      1
                    )
                  }
                },
                {
                  key: "remark",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c("el-input", {
                          model: {
                            value: row.remark,
                            callback: function($$v) {
                              _vm.$set(row, "remark", $$v)
                            },
                            expression: "row.remark"
                          }
                        })
                      ],
                      1
                    )
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return _c(
                      "div",
                      {},
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "li-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function() {
                                return _vm.orderList.splice($index, 1)
                              }
                            }
                          },
                          [_vm._v("移除")]
                        )
                      ],
                      1
                    )
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v("取消")
            ]),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.save } },
              [_vm._v("确定")]
            )
          ],
          1
        )
      ]),
      _c("select-materials", {
        ref: "selectMaterials",
        attrs: {
          "select-materials": _vm.selectMaterials,
          visible: _vm.showMaterials,
          "is-producible": false,
          "is-add": true
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials,
          addMaterial: _vm.addMaterial
        }
      }),
      _c("edit-materials", {
        ref: "editMaterials",
        on: { onSuccess: _vm.onSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { qualityTaskStatus, isKeyMaterials } from '@/config/options.config'

  export const searchFormList = [
    {
      colSpan: 6,
      key: 'status',
      tagName: 'el-select',
      props: {
        placeholder: '请选择状态'
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: qualityTaskStatus
        }
      ]
    },
    {
        colSpan: 6,
        key: 'isExemption',
        tagName: 'el-select',
        props: {
          placeholder: '是否免检'
        },
        children: [
          {
            tagName: 'el-option',
            props: {
              label: 'name',
              value: 'id'
            },
            options: isKeyMaterials
          }
        ]
      },
    {
      colSpan: 6,
      key: 'materialsSearch',
      tagName: 'el-input',
      defaultValue: 1,
      attrs: {
        placeholder: '请输入物料编码/名称/规格'
      },
      children: []
    },
    {
        colSpan: 6,
        key: 'batchNumber',
        tagName: 'el-input',
        defaultValue: 1,
        attrs: {
          placeholder: '请输入批次码'
        },
        children: []
      },
    {
      colSpan: 6,
      key: 'sequenceNumber',
      tagName: 'el-input',
      defaultValue: 1,
      attrs: {
        placeholder: '请输入序列号'
      },
      children: []
    },
    {
      colSpan: 6,
      key: 'materialsReceivedOrderCode',
      tagName: 'el-input',
      attrs: {
        placeholder: '请输入收料单号'
      }
    },
    {
      colSpan: 6,
      key: 'purchaseOrderCode',
      tagName: 'el-input',
      attrs: {
        placeholder: '请输入采购单号'
      }
    },
    {
        colSpan: 6,
        key: 'supplierName',
        tagName: 'el-input',
        attrs: {
          placeholder: '请输入供应商'
        }
      }
  ]

  
  
import { inspectionItemType, status } from '@/config/options.config'
import { formatColumns } from '@/utils'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'inspectionEntryName',
    label: '检验项名称',
    slotName: 'inspectionEntryName',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'inspectionMethod',

    prop: 'inspectionMethod',
    label: '检验方法',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'inspectionStandard',

    prop: 'inspectionStandard',
    label: '检验标准',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'isEnabled',
    label: '启用状态',
    sortable: false,
    filters: formatColumns(status),
    slotName: 'isEnabled'
  },
  {
    prop: 'defaultValue',
    label: '默认值',
    hiddenSearch: true,
    slotName:'defaultValue',
    sortable: false
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 190
  }
]

export const formListColumns = [
  {
    prop: 'inspectionEntryName',
    label: '检验项名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {

    prop: 'inspectionMethod',
    label: '检验方法',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', required: false }
  },
  {

    prop: 'inspectionStandard',
    label: '检验标准',
    sortable: false,
    hiddenSearch: true,
    slotName: 'inspectionStandard',

    form: {
      tagName: 'el-input',
      colSpan: 12,
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    prop: 'defaultValue',
    label: '默认值',
    sortable: false,
    hiddenSearch: true,
    slotName: 'defaultValue',
    form: {
      tagName: 'el-input',
      colSpan: 12,
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    prop: 'parameterType',
    label: '检验值类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'parameterType',
    form: {colSpan: 12, defaultValue: 1, tagName: 'el-select', props: { clearable: false }, options: inspectionItemType.filter(item => item.id !== 4), on: { change: (val) => { functionList.paramsTypeChange(val) } }}
  },
  {
    prop: 'possibleValue',
    label: '可能值',
    sortable: false,
    hiddenSearch: true,
    slotName: 'possibleValue',
    form: {
      hidden: true,
      tagName: 'el-select',
      props: {
        multiple: true,
        filterable: true,
        allowCreate: true,
        defaultFirstOption: true,
        placeholder: '请输入可能值，敲击回车'
      },
      on: { change: (val) => { functionList.possibleValueChange(val) } }
    }
  },

  {
    prop: 'isEnabled',
    label: '启用状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isEnabled',
    filters: formatColumns(status),
    width: 120,
    form: {
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      colSpan: 24,
      required: false,
      props: { type: 'textarea' }
    }
  }
]

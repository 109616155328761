var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fault-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          staticStyle: { "margin-bottom": "10px" },
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "process" } }, [
            _vm._v("来料检")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("SearchForm", {
                ref: "searchForm",
                attrs: {
                  "form-list-extend": _vm.searchFormList,
                  "form-data": _vm.searchFormData,
                  searchType: 1
                },
                on: {
                  "update:formData": function(data) {
                    return (_vm.searchFormData = data)
                  },
                  search: _vm.searchTable
                }
              })
            ],
            1
          ),
          _c("el-divider", { staticClass: "e-divider" }),
          _c("FunctionHeader", {
            staticStyle: { padding: "10px 0 10px 0" },
            attrs: {
              "search-title": "输入任务编号搜索",
              tags: _vm.functionHeader.tags
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          }),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.columnsAll,
              height: _vm.height,
              "set-data-method": _vm.getTableData
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "number",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.isShowChild
                        ? _c("NewPageOpen", {
                            attrs: {
                              path: {
                                name: "qualityInspectionOrderDetail",
                                query: { id: row.id, isView: "view" }
                              },
                              text: row.number
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }
              },
              {
                key: "inspectionType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(_vm.inspectionTypeNames[row.type]))
                  ])
                }
              },
              {
                key: "isKeyMaterials",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(row.isKeyMaterials ? "是" : "否"))
                  ])
                }
              },
              {
                key: "isExemption",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(row.isExemption ? "是" : "否"))
                  ])
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", { staticClass: "is-enabled" }, [
                    _c("div", {
                      style: {
                        background:
                          _vm.qualityTaskStatus[row.status].background,
                        color: _vm.qualityTaskStatus[row.status].color
                      }
                    }),
                    _c(
                      "div",
                      {
                        style: {
                          color: _vm.qualityTaskStatus[row.status].color
                        }
                      },
                      [_vm._v(_vm._s(_vm.qualityTaskStatus[row.status].name))]
                    )
                  ])
                }
              },
              {
                key: "allCount",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(_vm._s(row.okCount) + "/" + _vm._s(row.ngCount))
                    ])
                  ])
                }
              },
              {
                key: "result",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c(
                      "span",
                      {
                        style: {
                          color:
                            row.result == 0
                              ? "#E02020"
                              : row.result === 1
                              ? "#00AB29"
                              : ""
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.result === 1
                                ? "合格"
                                : row.result === 0
                                ? "不合格"
                                : "-"
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    { staticStyle: { "z-index": "100" } },
                    [
                      _vm.permission("Detail")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.viewDetail(row.id)
                                }
                              }
                            },
                            [_vm._v("详情")]
                          )
                        : _vm._e(),
                      _vm.permission("Check") && row.status != 2
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.check(row.id)
                                }
                              }
                            },
                            [_vm._v("检验")]
                          )
                        : _vm._e(),
                      _vm.permission("Del") && row.status != 2
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.del(row.id)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
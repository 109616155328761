var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("质检任务详情")])
          ]
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("任务编号：" + _vm._s(_vm.detailData.code))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "业务类型：" +
                    _vm._s(_vm.inspectionTypeNames[_vm.detailData.type])
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "质检方案：" + _vm._s(_vm.detailData.inspectionSchemeName)
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "质检状态：" +
                    _vm._s(
                      _vm.detailData.status || _vm.detailData.status == 0
                        ? _vm.qualityTaskStatus[_vm.detailData.status].name
                        : ""
                    )
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("生产工单：" + _vm._s(_vm.detailData.producePlanNumber))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("生产产品：" + _vm._s(_vm.detailData.materialsFullName))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("生产任务：" + _vm._s(_vm.detailData.productionTaskCode))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("工序：" + _vm._s(_vm.detailData.procedureName))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("创建人：" + _vm._s(_vm.detailData.createUser))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("创建时间：" + _vm._s(_vm.detailData.createTime))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("任务开始时间：" + _vm._s(_vm.detailData.startTime))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("任务结束时间：" + _vm._s(_vm.detailData.endTime))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("执行人：" + _vm._s(_vm.detailData.executorName))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("业务部门：" + _vm._s(_vm.detailData.businessUnitName))
              ])
            ]),
            _vm.type === "view"
              ? _c("el-col", { attrs: { span: 6 } }, [
                  _c("span", { staticClass: "bot-ti" }, [
                    _vm._v("结论判定： "),
                    _c(
                      "span",
                      {
                        style: {
                          color:
                            _vm.detailData.result == 0
                              ? "#E02020"
                              : _vm.detailData.result == 1
                              ? "#00AB29"
                              : ""
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.detailData.result == 0
                                ? "不合格"
                                : _vm.detailData.result == 1
                                ? "合格"
                                : ""
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _vm.detailData.result == 0 && _vm.type === "view"
              ? _c("el-col", { attrs: { span: 6 } }, [
                  _c("span", { staticClass: "bot-ti" }, [
                    _vm._v("处理意见：" + _vm._s(_vm.detailData.suggestion))
                  ])
                ])
              : _vm._e(),
            _vm.type === "view"
              ? _c("el-col", { attrs: { span: 6 } }, [
                  _c(
                    "span",
                    { staticClass: "bot-ti" },
                    [
                      _vm._v("附件： "),
                      _vm._l(_vm.detailData.attachment, function(item) {
                        return _c(
                          "el-link",
                          {
                            key: item.id,
                            staticStyle: { "padding-left": "10px" },
                            attrs: { href: item.url, target: "_blank" }
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      })
                    ],
                    2
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "detail-card" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "detail-tabs",
              attrs: { type: "border-card" },
              model: {
                value: _vm.activeTabName,
                callback: function($$v) {
                  _vm.activeTabName = $$v
                },
                expression: "activeTabName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "按检验对象", name: "按检验对象" } },
                [
                  _c(
                    "div",
                    { staticClass: "tables" },
                    [
                      _vm.projectLength > 0
                        ? _c("div", { staticStyle: { display: "flex" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  "padding-bottom": "10px"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-arrow-left",
                                  style: {
                                    color:
                                      _vm.currentPage == 1
                                        ? "#C0C4CC"
                                        : "#303133",
                                    cursor:
                                      _vm.currentPage === 1
                                        ? "not-allowed"
                                        : "pointer"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.beforePage()
                                    }
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      display: "inline-block",
                                      width: "35px",
                                      "text-align": "center"
                                    }
                                  },
                                  [_vm._v(_vm._s(this.currentPage))]
                                ),
                                _c("i", {
                                  staticClass: "el-icon-arrow-right",
                                  style: {
                                    color:
                                      _vm.currentPage == _vm.projectLength
                                        ? "#C0C4CC"
                                        : "#303133",
                                    cursor:
                                      _vm.currentPage == _vm.projectLength
                                        ? "not-allowed"
                                        : "pointer"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.nextPage()
                                    }
                                  }
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { color: "#6B8AFF" } },
                                  [
                                    _vm._v(
                                      " 第" + _vm._s(_vm.currentPage) + "份 "
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { color: "#909399" } },
                                  [
                                    _vm._v(
                                      "/共" + _vm._s(_vm.projectLength) + "份"
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm.type != "view"
                              ? _c("div", [
                                  _c("i", {
                                    staticClass: "el-icon-remove-outline",
                                    staticStyle: {
                                      color: "#909399",
                                      "margin-left": "6px"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.delPart()
                                      }
                                    }
                                  }),
                                  _c("i", {
                                    staticClass: "el-icon-circle-plus-outline",
                                    staticStyle: {
                                      color: "#909399",
                                      "margin-left": "6px"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.addPart()
                                      }
                                    }
                                  })
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _c("MTable", {
                        ref: "mTable",
                        attrs: {
                          "show-page": false,
                          height: 410,
                          columns: _vm.columnsDetail,
                          data: _vm.newprojectList[_vm.currentPage - 1]
                            ? _vm.newprojectList[_vm.currentPage - 1]
                                .inspectionItemList
                            : []
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "index",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "attachments",
                            fn: function(ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return _c("div", {}, [
                                _vm.type === "view"
                                  ? _c("div", [
                                      row.attachments
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#6B8AFF",
                                                cursor: "pointer"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.viewPicture(row)
                                                }
                                              }
                                            },
                                            [_vm._v("查看")]
                                          )
                                        : _c("div", [_vm._v("-")])
                                    ])
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-upload",
                                          _vm._b(
                                            {
                                              ref: "upload",
                                              attrs: {
                                                "file-list": row.attachments,
                                                accept: ".png,.jpg",
                                                "on-remove": function(file) {
                                                  return _vm.fileRemove(
                                                    file,
                                                    $index
                                                  )
                                                }
                                              }
                                            },
                                            "el-upload",
                                            _vm.uploadProps,
                                            false
                                          ),
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  color: "#6B8AFF",
                                                  cursor: "pointer"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.getRowIndex(
                                                      $index
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("上传")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                              ])
                            }
                          },
                          {
                            key: "measuredValue",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm.type === "view"
                                  ? _c(
                                      "span",
                                      {
                                        style: {
                                          color: _vm.fixData(row)
                                            ? "#E02020"
                                            : ""
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm._f("fixValue")(row)))]
                                    )
                                  : _c(
                                      "div",
                                      [
                                        row.parameterType === 0
                                          ? _c("el-input-number", {
                                              class:
                                                row.standardValue != null
                                                  ? row.measuredValue >
                                                      row.upperLimit +
                                                        row.standardValue ||
                                                    row.measuredValue <
                                                      row.standardValue -
                                                        row.lowerLimit
                                                    ? "red-group"
                                                    : ""
                                                  : "",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "controls-position": "right"
                                              },
                                              on: {
                                                change: function(val) {
                                                  _vm.handleChange(val, row)
                                                }
                                              },
                                              model: {
                                                value: row.measuredValue,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "measuredValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.measuredValue"
                                              }
                                            })
                                          : _vm._e(),
                                        row.parameterType === 1
                                          ? _c("el-input", {
                                              model: {
                                                value: row.measuredValue,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "measuredValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.measuredValue"
                                              }
                                            })
                                          : _vm._e(),
                                        row.parameterType === 2
                                          ? _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value: row.measuredValue,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "measuredValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.measuredValue"
                                                }
                                              },
                                              _vm._l(
                                                row.possibleValue,
                                                function(val) {
                                                  return _c("el-option", {
                                                    key: val,
                                                    attrs: {
                                                      label: val,
                                                      value: val
                                                    }
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e(),
                                        row.parameterType === 3
                                          ? _c(
                                              "el-select",
                                              {
                                                attrs: { multiple: "" },
                                                model: {
                                                  value: row.measuredValue,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "measuredValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.measuredValue"
                                                }
                                              },
                                              _vm._l(
                                                row.possibleValue,
                                                function(val) {
                                                  return _c("el-option", {
                                                    key: val,
                                                    attrs: {
                                                      label: val,
                                                      value: val
                                                    }
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                              ])
                            }
                          },
                          {
                            key: "remark",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  _vm.type === "view"
                                    ? _c("span", [_vm._v(_vm._s(row.remark))])
                                    : _c("el-input", {
                                        model: {
                                          value: row.remark,
                                          callback: function($$v) {
                                            _vm.$set(row, "remark", $$v)
                                          },
                                          expression: "row.remark"
                                        }
                                      })
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "materialsCode",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewMaterialsDetail(
                                        row.materialsId
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(row.materialsCode))]
                              )
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm.type === "view"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "按检验项", name: "按检验项" } },
                    [
                      _c(
                        "div",
                        { staticClass: "m-table" },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%", height: "430px" },
                              attrs: {
                                data: _vm.tableData,
                                "row-style": {
                                  height: _vm.customTableRowHeight + "px"
                                }
                              }
                            },
                            [
                              _c(
                                "el-table-column",
                                { attrs: { align: "center", label: "检验组" } },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "partSort",
                                      label: "检验对象\\检验项",
                                      width: "150"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.partSort + 1
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2151516586
                                    )
                                  })
                                ],
                                1
                              ),
                              _vm._l(_vm.tableHeader, function(value, key) {
                                return _c(
                                  "el-table-column",
                                  {
                                    key: key,
                                    attrs: { align: "center", label: key }
                                  },
                                  _vm._l(value, function(item) {
                                    return _c(
                                      "el-table-column",
                                      {
                                        key: item.id,
                                        attrs: { minWidth: "120" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.fixResult(
                                                          scope.row.result,
                                                          item
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "header" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { padding: "6px" }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.inspectionItemName
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "top-start"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content"
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        "检验方法：" +
                                                          _vm._s(
                                                            item.inspectionMethod
                                                          )
                                                      )
                                                    ]),
                                                    _c("div", [
                                                      _vm._v(
                                                        "检验标准：" +
                                                          _vm._s(
                                                            item.inspectionStandard
                                                          )
                                                      )
                                                    ]),
                                                    item.parameterType === 0
                                                      ? _c("div", [
                                                          _vm._v(
                                                            "标准值：" +
                                                              _vm._s(
                                                                item.standardValue
                                                              )
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                ),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-question"
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      2
                                    )
                                  }),
                                  1
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "updateBy",
                                  label: "更新人",
                                  width: "150"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "updateTime",
                                  label: "更新时间",
                                  width: "180"
                                }
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.type !== "view"
        ? _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticStyle: { display: "flex" } }, [
              _c(
                "div",
                {
                  staticClass: "card-bot",
                  staticStyle: {
                    "flex-wrap": "nowrap",
                    "align-items": "center"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "bot-ti",
                      staticStyle: { "margin-bottom": "0px" }
                    },
                    [_vm._v("合格状态：")]
                  ),
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.detailData.result,
                        callback: function($$v) {
                          _vm.$set(_vm.detailData, "result", $$v)
                        },
                        expression: "detailData.result"
                      }
                    },
                    _vm._l(_vm.resultList, function(val) {
                      return _c("el-option", {
                        key: val.name,
                        attrs: { label: val.name, value: val.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.detailData.result === 0,
                      expression: "detailData.result === 0"
                    }
                  ],
                  staticClass: "card-bot",
                  staticStyle: {
                    "flex-wrap": "nowrap",
                    "align-items": "center"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "bot-ti",
                      staticStyle: { "margin-bottom": "0px" }
                    },
                    [_vm._v("处理意见：")]
                  ),
                  _c(
                    "div",
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.detailData.suggestion,
                          callback: function($$v) {
                            _vm.$set(_vm.detailData, "suggestion", $$v)
                          },
                          expression: "detailData.suggestion"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]),
            _c(
              "div",
              {
                staticClass: "card-bot",
                staticStyle: { "flex-wrap": "nowrap", "align-items": "center" }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "bot-ti",
                    staticStyle: { "margin-bottom": "0px" }
                  },
                  [_vm._v("附件：")]
                ),
                _c(
                  "div",
                  { staticClass: "li-des" },
                  [
                    _c(
                      "el-upload",
                      _vm._b(
                        {
                          ref: "upload",
                          staticClass: "upload-demo",
                          style: {
                            width:
                              _vm.detailData.attachment.length > 3
                                ? "140px"
                                : "150px"
                          },
                          attrs: {
                            "file-list": _vm.detailData.attachment.slice(
                              0,
                              _vm.textFlg ? _vm.detailData.attachment.length : 3
                            )
                          }
                        },
                        "el-upload",
                        _vm.taskUploadProps,
                        false
                      ),
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "small", type: "primary" } },
                          [_vm._v("点击上传")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.detailData.attachment.length > 3,
                            expression: "detailData.attachment.length > 3"
                          }
                        ],
                        staticClass: "more",
                        on: {
                          click: function($event) {
                            _vm.textFlg = !_vm.textFlg
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.textFlg ? "收起" : "展开") + " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v(_vm._s(_vm.type !== "view" ? "取 消" : "返 回"))
            ]),
            _vm.type !== "view"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.save(0)
                      }
                    }
                  },
                  [_vm._v("暂 存")]
                )
              : _vm._e(),
            _vm.type !== "view"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.save(1)
                      }
                    }
                  },
                  [_vm._v("完成检验")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c(
        "MDialog",
        {
          attrs: {
            title: "现场图片",
            width: "600px",
            height: "500px",
            "show-footer": false
          },
          on: {
            onOk: function($event) {
              _vm.pictureVisible = false
            }
          },
          model: {
            value: _vm.pictureVisible,
            callback: function($$v) {
              _vm.pictureVisible = $$v
            },
            expression: "pictureVisible"
          }
        },
        _vm._l(_vm.formatArr(_vm.imgList), function(item) {
          return _c("el-image", {
            key: item,
            staticClass: "table-row-img",
            staticStyle: { "padding-left": "10px" },
            attrs: { src: item, "preview-src-list": _vm.formatArr(_vm.imgList) }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="inspection-project-new">
    <div class="group">
      <div class="top">
        <div class="title">检验组</div>
        <img src="@/assets/quality/加号2-fill@2x.png" alt="" width="16" @click="addGroup">
      </div>
      <div class="content" :style="{height: height + 44 + 'px'}">
        <div v-for="item in inspectionGroupList" :key="item.id" :class="{ active: item.id === getTableDataParams.inspectionProjectId }" @click="getInspectionItems(item)">
          <span class="ellipsis" :title="item.name">{{ item.name }}</span>
          <div style="white-space: nowrap;">
            <span class="modify" style="margin-right: 10px;" @click.stop="modifyOrCopyGroup(item, 'modify')" />
            <span class="del" @click.stop="delGroup(item)" />
          </div>
        </div>
      </div>
    </div>
    <div class="item" :style="{ width: open ? 'calc(100% - 448px)' : 'calc(100% - 220px)' }">
      <template v-if="getTableDataParams.inspectionProjectId">
        <div class="top"><div class="title">检验项</div><i /></div>
        <FunctionHeader
          v-model="functionHeader.searchVal"
          search-title="请输入检验项名称"
          :tags=" !selectItems.length ? functionHeader.tags : []"
          :export-name="functionHeader.exportName"
          :export-params="functionHeader.exportParams"
          :show-export="!selectItems.length"
          :import-name="!selectItems.length ? functionHeader.importName : ''"
          @search="$refs.mTable.setTableData(true)"
        >
          <el-button v-if="permission('BatchDel') && selectItems.length" slot="all" type="primary" size="small" style="margin-left: 10px;"
          @click="batchDel">批量删除</el-button>
        </FunctionHeader>
        <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <el-button slot="inspectionEntryName" slot-scope="{ row }" type="text" @click="viewDetail(row)">{{ row.inspectionEntryName }}</el-button>
          <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
            <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
            <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
          </div>
          <div slot="defaultValue" slot-scope="{ row }">
            <div>{{ row.parameterType === 3 && row.defaultValue ? JSON.parse(row.defaultValue).join(',') : row.defaultValue}}</div>
          </div>
          
          <div slot="inspectionMethod" slot-scope="{ row }" >
            <el-tooltip :content="extractBeforeHttps(row.inspectionMethod)" placement="top">
              <div>
                <div>{{ extractBeforeHttps(row.inspectionMethod)  }}</div>
            <img v-if="extractAfterHttps(row.inspectionMethod)" style="width: 40px; height: 40px;" :src="extractAfterHttps(row.inspectionMethod)" alt="" srcset="">
              </div>

          </el-tooltip>
          </div>

          <el-tooltip slot="inspectionStandard" slot-scope="{ row }" :content="extractBeforeHttps(row.inspectionStandard)" placement="top">
            <div>
                <div>{{ extractBeforeHttps(row.inspectionStandard)  }}</div>
            <img v-if="extractAfterHttps(row.inspectionStandard)" style="width: 40px; height: 40px;" :src="extractAfterHttps(row.inspectionStandard)" alt="" srcset="">
              </div>
          </el-tooltip>
          <div slot="action" slot-scope="{ row }">
            <el-button v-if="permission('ToHot')" type="text" @click="toHot(row)">加入常用库</el-button>
            <el-divider v-if="permission('ToHot')" direction="vertical" />
            <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">编辑</el-button>
            <el-divider v-if="permission('Modify')" direction="vertical" />
            <el-button v-if="permission('Del')" type="text" @click="del(row)">删除</el-button>
          </div>
        </MTable>
      </template>
    </div>
    <div class="hot" :style="{ width: open ? '220px' : 0}">
      <div class="top">
        <div class="title">常用库</div>
        <i />
      </div>
      <div class="content">
        <div class="input-wrapper"><el-input v-model="itemSearchValue" clearable size="middle" placeholder="请输入项目名称" @input="itemSearchValueChange" /></div>
        <div class="item-list" :style=" {height: open ? height - 8 + 'px' : 0}">
          <div v-for="item in inspectionItemSearchResult" :key="item.id">
            <div class="name ellipsis" :title="item.inspectionEntryName" @click="modifyHotInspectionItem(item)">{{ item.inspectionEntryName }}</div>
            <div class="icons" style="white-space: nowrap">
              <i class="el-icon-circle-plus-outline" title="加入检验组" @click="addInspectionGroup(item) " />
              <i class="el-icon-remove-outline" title="移除常用库" @click="delHotInspectionItems(item)" />
            </div>
          </div>
        </div>
      </div>
      <img :src="open ? closeImg : openImg" class="open-img" alt="" style="width: 19px;display: block" @click="open = !open">
    </div>
    <MDialog v-model="visible" :title="titles[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList">
        <el-upload slot="drawing" :file-list="attachments" v-bind="uploadProps2">
          <el-button type="primary" size="small">上传附件</el-button>
        </el-upload>
      </MFormBuilder>
    </MDialog>
    <MDialog v-model="hotVisible" :title="titles2[currentType]" @onOk="submitHotForm">
      <MFormBuilder ref="formBuild2" :form-data="formData" :form-list="formList">
        <el-upload slot="drawing" :file-list="attachments" v-bind="uploadProps2">
          <el-button type="primary" size="small">上传附件</el-button>
        </el-upload>
      </MFormBuilder>
    </MDialog>
    <MDialog v-model="groupVisible" width="400px" title="新增检验组" @onOk="submitGroupForm">
      <el-input v-model="formData.name" placeholder="请输入项目组名称" style="padding-right: 15px;" />
    </MDialog>
    <MDialog v-model="viewVisible" title="检验项详情" :showFooter="false">
      <el-row v-if="viewVisible" class="view-list" :gutter="24">
        <template v-for="(item, index) in formListColumns">
          <el-col
            v-if="item.prop !== 'possibleValue' || formData.parameterType === 2 || formData.parameterType === 3"
            :key="item.prop"
            :span="item.prop === 'remark' ? 24 : 12"
            :style="{marginBottom: index !== 4 ? '20px' : 0 }"
          >
            <span class="bot-tis">{{ item.label }}：</span>
            <span class="bot-tis">{{ getValue(item) }}</span>
          </el-col>
        </template>
        <el-col v-if="formData.attachments && formData.attachments.length" :span="12">
          <div style="display: flex">
            <div>附件：</div>
            <div>
              <div v-for="(at, index) in formData.attachments" :key="index" style="padding-bottom: 5px">
                <a :href="at.url" target="_blank" style="color: #607FFF">{{ at.name }}</a>
                <img src="@/assets/quality/路径@2x.png" alt="" style="width: 12px;margin-left: 5px">
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </MDialog>
  </div>
</template>

<script>
import openImg from '@/assets/information/open.png'
import closeImg from '@/assets/information/close.png'
import FunctionHeader from '@/components/FunctionHeader/index'
import conventionMixin from '@/mixin/conventional-page'
import { columns, getFunctionList, formListColumns } from './columns'
import formList from './form-list'
import projectGroupApi from '@/api/quality/inspection-project'
import api from '@/api/quality/inspection-items'
import { getToken } from '@/utils/auth'
export default {
  name: 'InspectionProject',
  components: { FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      openImg,
      closeImg,
      open: false,
      params: {
        radio: 'InspectionItems'
      },
      columns,
      formListColumns,
      inspectionGroupList: [],
      functionHeader: {
        searchVal: '',
        importName: 'inspectionItems',
        exportName: 'exportInspectionItems',
        exportParams: {},
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ]
      },
      itemSearchValue: '',
      inspectionItemList: [],
      inspectionItemSearchResult: [],
      selectItems: [],
      formData: {},
      formList,
      currentType: 'add',
      titles: {
        add: '新增检验项',
        modify: '修改检验项',
        copy: '复制检验项'
      },
      titles2: {
        add: '新增常用项',
        modify: '修改常用项',
        copy: '复制常用项'
      },
      visible: false,
      groupVisible: false,
      getTableDataParams: {
        inspectionProjectId: null
      },
      hotVisible: false,
      searchFieldName: 'inspectionEntryName',
      viewVisible: false,
      uploadProps2: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        onRemove: this.removeFile
      },
      attachments: []
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 56 - 44 - 12 - 37 - 40 - 25 - 12
    }
  },
  beforeMount() {
    getFunctionList({
      paramsTypeChange: this.paramsTypeChange,
      possibleValueChange: this.possibleValueChange
    })
  },
  mounted() {
    this.getHotInspectionItems()
    this.getInspectionGroupList()
  },
  methods: {
     extractBeforeHttps(url) {
      if(!url){
        return url
      }
    var httpsIndex = url.indexOf('https');
    if (httpsIndex !== -1) {
        return url.substring(0, httpsIndex);
    } else {
        return url; // 如果没有找到 https，则返回 null 或者可以根据情况返回原始字符串
    }
},
     extractAfterHttps(url) {
      if(!url){
        return url
      }
    var httpsIndex = url.indexOf('https');
    if (httpsIndex !== -1) {
        return url.substring(httpsIndex);
    } else {
        return ''; // 如果没有找到 https，则返回 null 或者可以根据情况返回原始字符串
    }
},
    async getInspectionGroupList() {
      const res = await projectGroupApi.getInspectionProjectList()
      if (res) {
        this.inspectionGroupList = res
        if (!this.getTableDataParams.inspectionProjectId) {
          this.getTableDataParams.inspectionProjectId = res[0].id
        }
      }
    },
    async getHotInspectionItems() {
      const res = await api.getHotInspectionItems()
      if (res) {
        this.inspectionItemList = res
        this.inspectionItemSearchResult = res
      }
    },
    add() {
      this.formData = {}
      this.currentType = 'add'
      this.attachments = []
      this.formList[5].children[0].options = []
      this.paramsTypeChange(1)
      this.visible = true
    },
    viewDetail(row) {
      this.formData = this._.cloneDeep(row)
      if (this.formData.attachments) {
        this.formData.attachments = JSON.parse(this.formData.attachments)
      } else {
        this.formData.attachments = []
      }
      this.viewVisible = true
    },
    getValue(row) {
      const result = this.formData[row.prop]
      if (row.form && row.form.options) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      if (row.prop === 'possibleValue') {
        return JSON.parse(result).join(',')
      }
      return result
    },
    getInspectionItems(row) {
      this.getTableDataParams.inspectionProjectId = row.id
      this.$refs.mTable.setTableData()
    },
    modifyOrCopy(row, command) {
      this.formData = this._.cloneDeep(row)
      this.formData.possibleValue = this.formData.possibleValue && JSON.parse(this.formData.possibleValue)
      if (this.formData.attachments) {
        this.attachments = JSON.parse(this.formData.attachments)
      } else {
        this.attachments = []
      }
      if ([0].includes(row.parameterType)) {
          this.formData.defaultValue = this.formData.defaultValue || this.formData.defaultValue === 0 ? this.formData.defaultValue : undefined
        }
        if ([3].includes(row.parameterType)) {
          this.formData.defaultValue = this.formData.defaultValue ? JSON.parse(this.formData.defaultValue) : []
        }
      if ([0, 1, 4].includes(row.parameterType)) {
        this.formData.possibleValue = []
      }
      this.paramsTypeChange(row.parameterType,'modify')
      this.currentType = command
      this.visible = true
    },
    itemSearchValueChange(val) {
      if (!val) {
        this.inspectionItemSearchResult = this.inspectionItemList
        return false
      }
      this.inspectionItemSearchResult = this.inspectionItemList.filter(item => item.inspectionEntryName.indexOf(val) === 0)
    },
    addGroup() {
      this.currentType = 'add'
      this.formData = {}
      this.groupVisible = true
    },
    modifyOrCopyGroup(row, type) {
      this.currentType = type
      this.formData = this._.cloneDeep(row)
      this.groupVisible = true
    },
    delGroup(row) {
      this.$confirm('删除检验组将连带删除之下的检验项，您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await projectGroupApi.delInspectionProject({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          if (this.getTableDataParams.inspectionProjectId === row.id) {
            this.getTableDataParams.inspectionProjectId = null
          }
          this.getInspectionGroupList()
        }
      })
    },
    selectionChange(data) {
      this.selectItems = data
    },
    async toHot(row) {
      const res = await api.addHotInspectionItems({
        inspectionEntryName: row.inspectionEntryName,
        inspectionProjectId: row.inspectionProjectId,
        parameterType: row.parameterType,
        defaultValue: row.defaultValue,
        possibleValue: row.possibleValue,
        inspectionMethod: row.inspectionMethod,
        inspectionStandard: row.inspectionStandard,
        attachments: row.attachments,
        isEnabled: row.isEnabled,
        remark: row.remark
      })
      if (res) {
        this.$message.success('加入成功')
        this.getHotInspectionItems()
      }
    },
    async addInspectionGroup(row) {
      const res = await api.addInspectionItems(this.createSetData(row))
      if (res) {
        this.$message.success('添加成功')
        this.$refs.mTable.setTableData()
      }
    },
    modifyHotInspectionItem(row) {
      this.formData = this._.cloneDeep(row)
      this.formData.possibleValue = this.formData.possibleValue && JSON.parse(this.formData.possibleValue)
      if (this.formData.attachments) {
        this.attachments = JSON.parse(this.formData.attachments)
      } else {
        this.attachments = []
      }
      if ([0].includes(row.parameterType)) {
          this.formData.defaultValue = this.formData.defaultValue || this.formData.defaultValue === 0 ? this.formData.defaultValue : undefined
        }
        if ([3].includes(row.parameterType)) {
          this.formData.defaultValue = this.formData.defaultValue ? JSON.parse(this.formData.defaultValue) : []
        }
      if ([0, 1, 4].includes(row.parameterType)) {
        this.formData.possibleValue = []
      }
      this.paramsTypeChange(row.parameterType,'modify')
      this.currentType = 'modify'
      this.hotVisible = true
    },
    delHotInspectionItems(row) {
      this.$confirm('您确定要从常用库移除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delHotInspectionItems(row.id)
        if (res) {
          this.$message.success('删除成功')
          this.getHotInspectionItems()
        }
      })
    },
    batchDel() {
      this.$confirm('您确定要删除检验项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.batchDelInspectionItems(this.selectItems.map(item => item.id).join('^'))
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    paramsTypeChange(val,type) {
      // 检验条目类型 0:数值 1:文本 2:单选 3:多选 4:附件
      this.formData.parameterType = val
      let possibleValue = this.formData.possibleValue ? this.formData.possibleValue.map(item => ({ id: item, name: item })) : []
      if (this.$refs.formBuild) {
        possibleValue = this.$refs.formBuild.form.possibleValue ? this.$refs.formBuild.form.possibleValue.map(item => ({ id: item, name: item })) : []
        this.$refs.formBuild.formRefs().clearValidate()
        this.$refs.formBuild.setForm([0, 1, 4].includes(val) ? {
          defaultValue: null,
          possibleValue: []
        } : {
          defaultValue: val === 2 ? null : []
        })
      }
      if(!type && Object.keys(this.formData).length){
        this.formData.defaultValue = val != 3 ? null : []
      }
      if (val === 0 || val === 1) {
        this.formList[5].hidden = true
        this.formList.splice(3, 1, {
          label: '默认值',
          key: 'defaultValue',
          tagName: val === 0 ? 'el-input-number' : 'el-input',
          props: val === 0 ? { controlsPosition: 'right' } : {type: 'textarea'},
          children: []
        })
      }
      if (val === 2 || val === 3) {
        this.formList[5].hidden = false
        this.formList.splice(3, 1, {
          label: '默认值',
          key: 'defaultValue',
          tagName: 'el-select',
          props: { multiple: val === 3 },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: possibleValue
            }
          ]
        })
      }
    },
    possibleValueChange(val) {
      this.formList[3].children[0].options = val.map(item => ({ name: item, id: item }))
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.attachments.push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },
    removeFile(file) {
      this.attachments = this.attachments.filter(item => item.uid !== file.uid)
    },
    async submitHotForm(callback) {
      const { form } = this.$refs.formBuild2
      this.$refs.formBuild2.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await this.api.modifyHotInspectionItems(this.createResetData(form))
          if (res) {
            this.getHotInspectionItems()
            this.hotVisible = false
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    async submitGroupForm(callback) {
      if (!this.formData.name) {
        callback()
        return this.$message.info('请输入检验组名称')
      }
      let res
      if (this.currentType === 'add' || this.currentType === 'copy') {
        res = await projectGroupApi.addInspectionProject({
          name: this.formData.name
        })
      }
      if (this.currentType === 'modify') {
        res = await projectGroupApi.modifyInspectionProject({
          id: this.formData.id,
          name: this.formData.name
        })
      }
      if (res) {
        this.groupVisible = false
        this.$message.success('新增成功')
        await this.getInspectionGroupList()
        this.getInspectionItems(this.inspectionGroupList[0])
      }
      callback()
    },
    createSetData(raw) {
      return {
        inspectionEntryName: raw.inspectionEntryName,
        inspectionProjectId: this.getTableDataParams.inspectionProjectId,
        parameterType: raw.parameterType,
        defaultValue: Array.isArray(raw.defaultValue) ? raw.defaultValue.length ? JSON.stringify(raw.defaultValue) : '' : raw.defaultValue || raw.defaultValue === 0 ? raw.defaultValue : '',
        possibleValue: [2, 3].includes(raw.parameterType) ? JSON.stringify(raw.possibleValue || []) : '',
        inspectionMethod: raw.inspectionMethod,
        inspectionStandard: raw.inspectionStandard,
        attachments: JSON.stringify(this.attachments),
        isEnabled: raw.isEnabled,
        remark: raw.remark
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.inspection-project-new {
  font-size: 14px;
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  background: #F0F2F5;
  overflow-x: hidden;

  .top {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    //border-bottom: 1px solid #BDC1CA;
    i {
      cursor: pointer;
      &:hover {
        color: #6B8AFF;
      }
    }
  }
  .item {
    width: calc(100% - 220px);
    padding: 0 10px;
    background: #ffffff;
    border-radius: 4px;
    padding-bottom: 7px;
    margin-left: 10px;
  }
  .group, .hot {
    background: #ffffff;
    border-radius: 4px;
    width: 220px;
  }
  .group {
    //border: 1px solid #BDC1CA;
    .content {
      padding: 10px;
      overflow-y: auto;
      >div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        border-radius: 4px;
        width: 185px;
        >div {
          display: flex;
          align-items: center;
          span {
            display: none;
            cursor: pointer;
            &:hover {
              color: #6B8AFF;
            }
          }
          span:first-child {
            background: url("../../../assets/quality/自定义标题@2x.png");
            background-size: 100% 100%;
            &:hover {
              background-size: 100% 100% !important;
              background: url("../../../assets/quality/自定义标题备份@2x.png");
            }
          }
          span:last-child {
            background: url("../../../assets/quality/3.0-删除@2x.png");
            background-size: 100% 100%;
            &:hover {
              background-size: 100% 100% !important;
              background: url("../../../assets/quality/3.0-删除备份@2x.png");
            }
          }
        }
        &.active {
          background: rgba(238, 244, 255, 1);
          >div {
            span {
              width: 14px;
              height: 14px;
              display: inline-block;
            }
          }
        }
        &:hover {
          background: rgba(238, 244, 255, 1);

          >div {
            span {
              width: 14px;
              height: 14px;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .hot {
    //border: 1px solid #BDC1CA;
    position: absolute;
    top: 12px;
    right: -1px;
    bottom: 26px;
    .open-img {
      position: absolute;
      left: -9px;
      top: 50%;
      transform: translateX(-50%);
      z-index: 100;
      cursor: pointer;
    }
    .content {
      .input-wrapper {
        padding: 10px;
      }
      .item-list {
        padding: 10px;
        overflow-y: auto;
        >div {
          padding: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          &:hover {
            background: rgba(238, 244, 255, 1);
          }
        }
        .icons {
          i:first-child {
            margin-right: 10px;
          }
          i {
            cursor: pointer;
            &:hover {
              color: #6B8AFF;
            }
          }
        }
      }
    }
  }
}
</style>

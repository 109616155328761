// 列表
export const columnsAll = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      sortable: false,
      width: 80
    },
    {
        prop: 'number',
        label: '单号',
        hiddenSearch: true,
        sortable: false,
        width: 160
      },
      {
        prop: 'businessType',
        label: '业务类型',
        hiddenSearch: true,
        sortable: false,
        slotName: 'inspectionType'
      },
      {
        prop: 'procedureName',
        label: '工序',
        hiddenSearch: true,
        sortable: false,
        width: 120
      },
    {
      prop: 'status',
      label: '状态',
      hiddenSearch: true,
      sortable: false,
      slotName: 'status',
      width: 120
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
      prop: 'materialsSpecifications',
      label: '物料规格',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'isKeyMaterials',
      label: '是否关键料',
      hiddenSearch: true,
      sortable: false,
      width: 100,
      slotName:'isKeyMaterials'
    },
    {
      prop: 'defectQuantity',
      label: '不良品数量',
      hiddenSearch: true,
      sortable: false,
      width: 100
    },
    {
      prop: 'batchNumber',
      label: '批次码',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'sequenceNumber',
      label: '序列号',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'supplierName',
      label: '供应商',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'purchaseOrderCode',
      label: '采购单',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'materialsReceivedOrderCode',
      label: '收料单',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'settingVariable',
      label: '设变件',
      hiddenSearch: true,
      sortable: false,
      width: 100,
      slotName:'isSettingVariable'
    },
    {
      prop: 'endTime',
      label: '处理时间',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
      prop: 'creator',
      label: '创建人',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'createTime',
      label: '创建时间',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
      prop: 'projectName',
      label: '项目',
      hiddenSearch: true,
      slotName:'projectName',
      sortable: false
    },
    {
      prop: 'sourceObject',
      label: '来源对象',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'sourceNumber',
      label: '源单号',
      hiddenSearch: true,
      sortable: false,
      slotName:'sourceNumber',
      width: 180
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      sortable: false,
      hiddenSearch: true,
      width: 120
    }
  ]


<template>
  <div class="fault-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" style="margin-bottom: 10px" @change="radioChange">
      <el-radio-button label="process">过程检</el-radio-button>
    </el-radio-group>
    <div class="bottom">
      <div class="top">
      <SearchForms ref="searchForms" :show-procedure="params.radio === 'process'" :form-option="formOptions" @resetData="resetData" @searchData="searchTable" />
    </div>
    <el-divider class="e-divider" />
    <FunctionHeader
      v-model="functionHeader.searchVal"
      search-title="输入任务编号搜索"
      :tags="functionHeader.tags"
      style="padding: 10px 0 10px 0"
      @search="$refs.mTable.setTableData(true)"
    >
    <div slot="all" v-show="selectIds.length">
        <el-button v-if="permission('Print')" type="primary" size="small" @click="handlePreview(selectIds,1)">批量打印</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }} 项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
      </div>
      <!-- <div slot="all" class="all-select">
          <div class="checkAll">
            <el-checkbox v-model="checkeds" :disabled="checkeds" :indeterminate="isIndeterminate" @change="selectCheck">全选</el-checkbox>
          </div>
          <div v-for="(item, index) in allStatus" :key="item.value" class="all-li" :class="item.checked ? 'active' : ''" @click="selectTab(item, index)">{{ item.label }}</div>
        </div> -->
    </FunctionHeader>
    <MTable ref="mTable" :columns="params.radio === 'process' ? columnsAll : incomming" :height="height" :set-data-method="getTableData"  @selection-change="selectionChanges">
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="number" slot-scope="{ row }">
        <NewPageOpen v-if="isShowChild" :path="{ name: 'qualityInspectionOrderDetail', query: {id: row.id, isView: 'view'}}" :text="row.number" />
      </div>
      <div slot="inspectionType" slot-scope="{ row }">{{ inspectionTypeNames[row.type] }}</div>
      <div slot="passRate" slot-scope="{ row }">{{ row.okCount > 0 ? ((row.okCount / row.batchCount) * 100).toFixed(2) : 0 }}%</div>
      <div slot="passAndUnPass" slot-scope="{ row }">{{ row.okCount }}/{{ row.ngCount }}</div>
      <div slot="status" slot-scope="{ row }" class="is-enabled">
        <div :style="{ background: qualityTaskStatus[row.status].background, color: qualityTaskStatus[row.status].color }" />
        <!-- <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" /> -->
        <div :style="{color: qualityTaskStatus[row.status].color }">{{ qualityTaskStatus[row.status].name }}</div>
      </div>
      <div slot="allCount" slot-scope="{ row }">
        <span>{{ row.okCount }}/{{ row.ngCount }}</span>
      </div>
      <div slot="result" slot-scope="{ row }">
        <span :style="{color:row.result == 0 ? '#E02020':row.result === 1 ? '#00AB29':''}">
          {{ row.result === 1 ? '合格' : row.result === 0 ? '不合格' : '-' }}
        </span>
      </div>
      <div slot="reports" slot-scope="{ row }">
        <a class="li-btn" @click="views(row)">查看</a>
      </div>
      <div slot="action" slot-scope="{ row }" style="z-index: 100">
        <el-button v-if="permission('Detail')" type="text" @click="viewDetail(row.id,'view')">详情</el-button>
        <el-button v-if="permission('Check') && row.status === 0" type="text" @click="viewDetail(row.id,'startCheck')">开始质检</el-button>
        <el-button v-if="permission('Check') && row.status === 1" type="text" @click="viewDetail(row.id,'continueCheck')">继续质检</el-button>
        <el-button v-if="permission('Del')" type="text" @click="del(row.id)">删除</el-button>
        <el-button v-if="permission('Print')" type="text" @click="handlePreview(row,0)">打印</el-button>
        <!-- <DelPopover v-if="permission('del') && row.status === 0" class="li-btn" @onOk="(callback) => del(row, callback)" />
          <a v-if="permission('del') && row.status !== 0" class="color-btns">删除</a>
          <el-divider v-if="permission('del')" direction="vertical" />
          <a v-if="permission('inspector') && row.status === 0" class="li-btn" @click="editInspector(row)">质检员</a>
          <a v-else class="color-btns">质检员</a> -->
      </div>
    </MTable>
    </div>

    <!-- 模板打印 -->
    <el-drawer
      title=""
      :visible.sync="dialog"
      :show-close="false"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
  >
  <div class="body-wrap" :style="{height: dialogHeight + 'px'}">
    <div style="display: flex;justify-content: center;">
      <i class="el-icon-printer icons" />

    </div>
    <div style="text-align:center">打印</div>
    <div style="margin-top: 20px;display: flex;align-items: center;justify-content: center;">
      <el-form :model="form">
      <el-form-item label="打印模板" :label-width="formLabelWidth">

        <el-select
        filterable
                v-model="form.type"
                placeholder="请选择打印模板"
                @change="changeTemplate"
              >
                <el-option
                  v-for="item in templateList"
                  :key="item.id"
                  :label="item.templateName"
                  :value="item.id"
                />
              </el-select>

      </el-form-item>
    </el-form>
    <i class="el-icon-setting" @click="setTemplate" style="margin-bottom: 12px;color:#607FFF;font-size: 22px;margin-left: 10px;cursor: pointer ;"></i>
    </div>

  </div>
    <div class="drawer-footer">
      <el-button @click="cancelForm">取 消</el-button>
      <el-button type="primary" @click="dowmLoadTemplate()" :loading="loading">{{ loading ? '下载中 ...' : '下 载' }}</el-button>
      <el-button type="primary" @click="printTemplate()" :loading="printLoading">打 印</el-button>
    </div>
</el-drawer>
  </div>
</template>
<script>
import FunctionHeader from '@/components/FunctionHeader'
import SearchForms from './search-forms'
import { qualityTaskStatus } from '@/config/options.config'
import api from '@/api/qualityManagement/quality-task'
import wapi from '@/api/production/work-order'
import fapi from '@/api/quality/inspection-plan'
import uapi from '@/api/sets/user/user'
import bapi from '@/api/information/print-label/batch-number'
import { columnsAll, incomming } from './columns'
import { Encrypt } from '@/utils/sercet'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import DelPopover from '@/components/DelPopover/index'
import { getUUid } from '@/utils'
import fixCommonPage from '@/mixin/fix-common-page'
import papi from '@/api/dataSetInfo/printingModule'
import dayjs from 'dayjs'
import Vue from 'vue'
import projectApi from '@/api/projectManagement/project'
export default {
  name: 'QualityTaskList',
  components: { DelPopover, NewPageOpen, FunctionHeader, SearchForms },
  mixins: [fixCommonPage],
  data() {
    return {
      showInspector: false,
      visibles: false,
      params: {
        radio: 'process'
      },
      info: {},
      taskData: [],
      isIndeterminate: true,
      qualityTaskStatus,
      searchFormData: [],
      searchFormDatas: {},
      keyword: '',
      selectKeyword: '1',
      functionHeader: {
        searchVal: ''
      },
      columnsAll,
      incomming,
      searchDatas: [],
      defaultSearchs: [{
        id: getUUid(),
        fieldName: 'inspectionType',
        fieldType: 'number',
        fieldValue: '1',
        operator: 'eq'
      }],
      currentId: undefined,
      formOptions: {
        reporterList: [],
        procedureOptions: [],
        inspectionOptions: [],
        batchNumberOptions: []
      },
      isFaultCheck: undefined,
      allStatus: [
        {
          value: 0,
          label: '待质检',
          checked: true
        },
        {
          value: 1,
          label: '质检中',
          checked: true
        },
        {
          value: 2,
          label: '已质检',
          checked: false
        }
      ],
      checkeds: false,
      currentIdxs: [0, 1],
      currentIdx: false,
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      inspectionTypeNames: ['首检', '过程检', '来料检', '出货检'],
      userList: [],
      inspectionSchemeInspector: [],
      inspectionSchemeInspectorId: undefined,
      formLabelWidth: '80px',
      loading:false,
      printLoading:false,
      dialog: false,
      form:{
        type:''
      },
      selectIds: [],
      total:0,
      selectionData:[],
      templateList:[],
      chooseIds:[],
      isPageLoaded:false
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 12 - 50
    },
      dialogHeight() {
        return this.$store.state.app.contentHeight - 65 - 76 - 60
      },
      getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  watch: {
    checkeds(val) {
      if (val) {
        this.currentIdxs = []
        this.isIndeterminate = false
        this.allStatus = this.allStatus.map(item => {
          return {
            ...item,
            checked: false
          }
        })
      } else {
        this.isIndeterminate = true
      }
    }
  },
  activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
  mounted() {
    this.isPageLoaded = true
    this.getUser()
    this.getSetting()
    this.getBusinessUnitList()
  },
  methods: {
    changeTemplate(val){
      localStorage.setItem('templateType', val)
    },
    selectionChanges(data) {
      this.selectionData = data
      this.selectIds = data.map(item => item.id)
    },
    resetSelectData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter((item) => item.selectable)
    },
    async dowmLoadTemplate(){
      this.loading = true
      const startTime = new Date().getTime()
       const res = await papi.commomDownload({
        wordTemplateId: this.form.type,
            type: "INSPECTION_TASK",
            idList: this.chooseIds,
            fileType:0
       })
       const endTime = new Date().getTime()
       const elapsedTime = endTime - startTime
       if (res && res.data.byteLength) {
        this.loading = false
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        let templateName = this.templateList.find(item => item.id === this.form.type).templateName
        const fileName = `${templateName}${dayjs().format('YYYYMMDDHHmmss')}.docx`
        this.downloadFile(urlP, fileName)
      }else{
          if (elapsedTime >= 300000) {
            this.dialog = false
            this.loading = false
            return this.$message.error('下载数据过多，请重新勾选')
         }
        }
    },
    downloadFile(url, name) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.download = name
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
    },

    async handlePreview(row,type) {
      await this.getAllWordTemplateType()
      if(!type){
        this.chooseIds = [row.id]
      }else{
        this.chooseIds = row
      }
      this.form.type = localStorage.getItem('templateType') ? +localStorage.getItem('templateType') : this.templateList.length ? this.templateList[0].id : ''
      this.dialog = true
      // window.open('https://view.officeapps.live.com/op/view.aspx?src=' + 'https://oss.mubyte.cn/static/finishedInspectionReport.docx')
    },
    async getAllWordTemplateType(){
        const res = await papi.getPrintingModuleListPage({
          page: 1,
          limit: 20
        })
        if(res){
          this.templateList = res.records
        }
      },
    setTemplate(){
      this.$router.push({ name: 'PrintingModule' })
    },
    async printTemplate() {
      this.printLoading = true
      const startTime = new Date().getTime()
        const res = await papi.commomDownload({
          wordTemplateId: this.form.type,
          type: "INSPECTION_TASK",
          idList: this.chooseIds,
          fileType: 1
        });

        const endTime = new Date().getTime()
        const elapsedTime = endTime - startTime
        if (res && res.data.byteLength) {
          this.printLoading = false
          const urlP = window.URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/pdf'
            })
          );
          window.open(urlP)
        }else{
          if (elapsedTime >= 300000) {
            this.dialog = false
            this.printLoading = false
            return this.$message.error('打印数据过多，请重新勾选')
         }
        }
    },
    cancelForm() {
      this.loading = false
      this.printLoading = false
      this.dialog = false
      while (Vue.Cancel.length > 0) {
        Vue.Cancel.shift()('cancel')
     }
    },
    async submitInspector(done) {
      done()
      const res = await api.modifyInspectionUser({
        id: this.inspectionSchemeInspectorId,
        inspectionSchemeInspectorList: this.inspectionSchemeInspector
      })
      if (res) {
        this.showInspector = false
        this.$message.success('修改成功')
        this.$refs.mTable.setTableData()
      }
    },
    async editInspector(row) {
      const user = row.inspectionSchemeInspector ? row.inspectionSchemeInspector.split('^') : []
      const users = user.filter(item => item)
      this.inspectionSchemeInspector = await users.map(item => Number(item))
      this.inspectionSchemeInspectorId = row.id
      this.showInspector = true
    },
    async views(row) {
      const res = await api.getInspectionOrderDetailById(row.id)
      if (res) {
        //   this.$refs.viewResult.view(res)
        this.visibles = true
      }
    },
    viewDetail(id,type) {
      this.$router.push({ name: 'QualityTaskDetail', query: { id,type }})
    },
    radioChange(label) {
      const types = [0, 'process', 'incomming', 'outProduct']
      this.defaultSearchs = [
        {
          id: getUUid(),
          fieldName: 'inspectionType',
          fieldType: 'number',
          fieldValue: types.indexOf(label),
          operator: 'eq'
        }
      ]
      this.searchDatas = []
      this.$refs.searchForms.searchFormData = {}
      this.keyword = ''
      this.selectKeyword = ''
      this.isShow = true
      this.$refs.mTable.getCheckedColumns()
      this.$refs.mTable.setTableData(true)
    },
    fixArr(val) {
      if (val) {
        const data = val.map(item => item.name)
        return data.split(',')
      }
    },
    checkResult(row) {
      this.$router.push({ name: 'qualityInspectionOrderDetail', query: { id: row.id }})
    },
    fixTime(val) {
      if (val) return Math.floor(val / 60) + '分钟'
    },
    selectTab(val, index) {
      // 判断是否是最后一个checked
      const isCheck = this.allStatus.filter(item => item.checked)
      const isAll = this.allStatus.filter(item => !item.checked)
      console.log('zhuantqwai=>', isCheck, isAll)
      this.allStatus[index].checked = !this.allStatus[index].checked
      if (this.currentIdxs.includes(val.value)) {
        this.currentIdxs = this.currentIdxs.filter(item => item !== val.value)
        if (isCheck.length === 1) {
          this.checkeds = true
        } else {
          this.checkeds = false
        }
      } else {
        if (isAll.length === 1) {
          this.checkeds = true
        } else {
          this.checkeds = false
        }
        this.currentIdxs.push(val.value)
      }
      this.$refs.mTable.setTableData(true)
    },
    selectCheck() {
      if (this.checkeds) {
        this.currentIdxs = []
        this.$refs.mTable.setTableData(true)
      }
    },
    fixSinImg(img) {
      const imgs = img.split(',')
      return imgs[0]
    },
    fixImg(img) {
      const imgs = img.split(',')
      return imgs
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, order } = condition
      const searchVal = []
      const data = Object.assign({}, ...this.searchDatas)
      const res = await api.getQualityTaskListPage({
        page: page.currentPage,
        size: page.pageSize,
        sort: order,
        code: this.functionHeader.searchVal,
        ...data
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    // 方案
    async getSetting() {
      const res = await fapi.getInspectionPlanList()
      if (res) {
        this.formOptions.inspectionOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.inspectionSchemeList = res
        }
      }
    },
    // 工序
    async getOperatorPages() {
      const res = await wapi.getOperatorPage()
      if (res) {
        this.formOptions.procedureOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.getCurrentProcedureList = res
        }
      }
    },
    // 用户
    async getUser() {
      const res = await uapi.getUserList()
      if (res) {
        this.userList = res
        this.formOptions.reporterList = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.reporterList = res
        }
      }
    },
    // 业务部门
    async getBusinessUnitList() {
      const res = await projectApi.getBusinessUnitList()
      if (res) {
        // this.formOptions.businessList = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.businessList = res
        }
      }
    },
    async del(id) {
      this.$confirm('删除后不可恢复，是否确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.del({ inspectionTaskIds: [id] })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    // 查询
    searchTable(data, val, keyword) {
      this.searchDatas = data
      this.keyword = keyword
      this.selectKeyword = val
      if (this.$refs.mTable !== undefined) {
        this.$refs.mTable.setTableData(true)
      }
    },
    // 重置
    resetData() {
      this.searchDatas = []
      this.selectKeyword = ''
      this.keyword = ''
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

  <style scoped lang="scss">
    .icons {
      font-size: 120px;
      color: #dadada;
      margin: 10px;
    }
    .setting {
      width: 15px;
     }
     .drawer-footer {
        padding: 10px;
        box-shadow: 4px -4px 12px -6px rgba(0, 0, 0, 0.1);
        text-align: center;
        button {
          padding: 10px 30px !important;
          &:first-child {
            background: #F8F8FB;
            color: #8A8CA5;
            transition: all .5s;
            &:hover {
              border-color: #dadaf6;
              background: #f0f0f8;
            }
          }
          &:last-child {
            background: $primary-color;
            color: #ffffff;
            transition: all .5s;
            &:hover {
              background: #7691fc;
            }
          }
        }
}
   .fault-wrap{
      // padding: 10px;
      .item-wrapper {
        > div {
          display: inline-flex;
          padding: 10px 5px 0 5px;
          align-items: center;
          font-size: 14px;

          .label {
            min-width: 115px;
          }

        }
      }
      .bottom {
        width: 100%;
        height: 100%;
        padding: 10px;
      }
      .all-select {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .checkAll {
          margin-right: 20px;
        }
        .all-li {
          width: 80px;
          height: 26px;
          background: #FFFFFF;
          border-radius: 16px;
          border: 1px solid #DDE3EB;
          margin-right: 15px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #A4B3C6;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .active {
          color: #607FFF;
          border-color: #607FFF;
          background: #EFF2FF;
        }
      }
      .li-btn{
        // margin-right:10px;
        color:#607FFF;
        font-weight: 500;
      }
      .color-btns{
        color:#c4c4c4;
      }
      .searchs{
        padding-left: 0;
        padding-top: 0;
      }
      .input-select{
        width: 100px;
        display: block;
        }
      .input-with-select{
        width: 100%;
      }
      .all-operator{
        height: 32px;
        margin-left: 10px;
        ::v-deep{
          .el-button-group .el-button{
            height: 32px;
          }
        }
      }
   }
   ::v-deep .customer-content div {
     width: 100%;
   }

   .tip-tit {
     height: 18px;
     line-height: 18px;
   }
   div.tooltip {
    display: inline-block;
    padding: 3px 5px;
    background: #303133;
    position: relative;
    border-radius: 3px;
    font-size: 16px;
    color: #ffffff;
    box-shadow: 2px 2px 2px 0 #c1c1c1;
    margin-left: 10px;
  }

  div.tooltip:before, div.tooltip:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    border: 3px solid transparent;
  }

  div.tooltip:before {
    border-right-color: #303133;
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  div.tooltip:after {
    border-right-color: #303133;
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .faultStatus {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    height: 22px;
    line-height: 22px;
    border-radius: 4px;
    width: 50px;
    text-align: center !important;
  }
  .drawing-img {
    width: 50px;
    height: 48px;
    display: block;
    cursor: pointer;
    color: #fff;
  }
  .faultDesc {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 180px;
  }
  ::v-deep{
    .e-divider{
      margin: 0px 0 0 0;
    }
    .demo-drawer {
      width: 400px !important;
  }
  }
  </style>

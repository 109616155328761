var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inspection-project-new" },
    [
      _c("div", { staticClass: "group" }, [
        _c("div", { staticClass: "top" }, [
          _c("div", { staticClass: "title" }, [_vm._v("检验组")]),
          _c("img", {
            attrs: {
              src: require("@/assets/quality/加号2-fill@2x.png"),
              alt: "",
              width: "16"
            },
            on: { click: _vm.addGroup }
          })
        ]),
        _c(
          "div",
          { staticClass: "content", style: { height: _vm.height + 44 + "px" } },
          _vm._l(_vm.inspectionGroupList, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                class: {
                  active: item.id === _vm.getTableDataParams.inspectionProjectId
                },
                on: {
                  click: function($event) {
                    return _vm.getInspectionItems(item)
                  }
                }
              },
              [
                _c(
                  "span",
                  { staticClass: "ellipsis", attrs: { title: item.name } },
                  [_vm._v(_vm._s(item.name))]
                ),
                _c("div", { staticStyle: { "white-space": "nowrap" } }, [
                  _c("span", {
                    staticClass: "modify",
                    staticStyle: { "margin-right": "10px" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.modifyOrCopyGroup(item, "modify")
                      }
                    }
                  }),
                  _c("span", {
                    staticClass: "del",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.delGroup(item)
                      }
                    }
                  })
                ])
              ]
            )
          }),
          0
        )
      ]),
      _c(
        "div",
        {
          staticClass: "item",
          style: {
            width: _vm.open ? "calc(100% - 448px)" : "calc(100% - 220px)"
          }
        },
        [
          _vm.getTableDataParams.inspectionProjectId
            ? [
                _vm._m(0),
                _c(
                  "FunctionHeader",
                  {
                    attrs: {
                      "search-title": "请输入检验项名称",
                      tags: !_vm.selectItems.length
                        ? _vm.functionHeader.tags
                        : [],
                      "export-name": _vm.functionHeader.exportName,
                      "export-params": _vm.functionHeader.exportParams,
                      "show-export": !_vm.selectItems.length,
                      "import-name": !_vm.selectItems.length
                        ? _vm.functionHeader.importName
                        : ""
                    },
                    on: {
                      search: function($event) {
                        return _vm.$refs.mTable.setTableData(true)
                      }
                    },
                    model: {
                      value: _vm.functionHeader.searchVal,
                      callback: function($$v) {
                        _vm.$set(_vm.functionHeader, "searchVal", $$v)
                      },
                      expression: "functionHeader.searchVal"
                    }
                  },
                  [
                    _vm.permission("BatchDel") && _vm.selectItems.length
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              slot: "all",
                              type: "primary",
                              size: "small"
                            },
                            on: { click: _vm.batchDel },
                            slot: "all"
                          },
                          [_vm._v("批量删除")]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("MTable", {
                  ref: "mTable",
                  attrs: {
                    columns: _vm.columns,
                    height: _vm.height,
                    "set-data-method": _vm.getTableData
                  },
                  on: { "selection-change": _vm.selectionChange },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "inspectionEntryName",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.viewDetail(row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.inspectionEntryName))]
                          )
                        }
                      },
                      {
                        key: "isEnabled",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", { staticClass: "is-enabled" }, [
                            _c("div", {
                              style: {
                                background: row.isEnabled
                                  ? "#24CAD7"
                                  : "#FFCC78"
                              }
                            }),
                            _c("div", [
                              _vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))
                            ])
                          ])
                        }
                      },
                      {
                        key: "defaultValue",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  row.parameterType === 3 && row.defaultValue
                                    ? JSON.parse(row.defaultValue).join(",")
                                    : row.defaultValue
                                )
                              )
                            ])
                          ])
                        }
                      },
                      {
                        key: "inspectionMethod",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: _vm.extractBeforeHttps(
                                      row.inspectionMethod
                                    ),
                                    placement: "top"
                                  }
                                },
                                [
                                  _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.extractBeforeHttps(
                                            row.inspectionMethod
                                          )
                                        )
                                      )
                                    ]),
                                    _vm.extractAfterHttps(row.inspectionMethod)
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "40px",
                                            height: "40px"
                                          },
                                          attrs: {
                                            src: _vm.extractAfterHttps(
                                              row.inspectionMethod
                                            ),
                                            alt: "",
                                            srcset: ""
                                          }
                                        })
                                      : _vm._e()
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "inspectionStandard",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: _vm.extractBeforeHttps(
                                  row.inspectionStandard
                                ),
                                placement: "top"
                              }
                            },
                            [
                              _c("div", [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.extractBeforeHttps(
                                        row.inspectionStandard
                                      )
                                    )
                                  )
                                ]),
                                _vm.extractAfterHttps(row.inspectionStandard)
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "40px",
                                        height: "40px"
                                      },
                                      attrs: {
                                        src: _vm.extractAfterHttps(
                                          row.inspectionStandard
                                        ),
                                        alt: "",
                                        srcset: ""
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ]
                          )
                        }
                      },
                      {
                        key: "action",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            [
                              _vm.permission("ToHot")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.toHot(row)
                                        }
                                      }
                                    },
                                    [_vm._v("加入常用库")]
                                  )
                                : _vm._e(),
                              _vm.permission("ToHot")
                                ? _c("el-divider", {
                                    attrs: { direction: "vertical" }
                                  })
                                : _vm._e(),
                              _vm.permission("Modify")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.modifyOrCopy(row, "modify")
                                        }
                                      }
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                              _vm.permission("Modify")
                                ? _c("el-divider", {
                                    attrs: { direction: "vertical" }
                                  })
                                : _vm._e(),
                              _vm.permission("Del")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.del(row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }
                      }
                    ],
                    null,
                    false,
                    3961051984
                  )
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "hot", style: { width: _vm.open ? "220px" : 0 } },
        [
          _vm._m(1),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "input-wrapper" },
              [
                _c("el-input", {
                  attrs: {
                    clearable: "",
                    size: "middle",
                    placeholder: "请输入项目名称"
                  },
                  on: { input: _vm.itemSearchValueChange },
                  model: {
                    value: _vm.itemSearchValue,
                    callback: function($$v) {
                      _vm.itemSearchValue = $$v
                    },
                    expression: "itemSearchValue"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "item-list",
                style: { height: _vm.open ? _vm.height - 8 + "px" : 0 }
              },
              _vm._l(_vm.inspectionItemSearchResult, function(item) {
                return _c("div", { key: item.id }, [
                  _c(
                    "div",
                    {
                      staticClass: "name ellipsis",
                      attrs: { title: item.inspectionEntryName },
                      on: {
                        click: function($event) {
                          return _vm.modifyHotInspectionItem(item)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.inspectionEntryName))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "icons",
                      staticStyle: { "white-space": "nowrap" }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-plus-outline",
                        attrs: { title: "加入检验组" },
                        on: {
                          click: function($event) {
                            return _vm.addInspectionGroup(item)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "el-icon-remove-outline",
                        attrs: { title: "移除常用库" },
                        on: {
                          click: function($event) {
                            return _vm.delHotInspectionItems(item)
                          }
                        }
                      })
                    ]
                  )
                ])
              }),
              0
            )
          ]),
          _c("img", {
            staticClass: "open-img",
            staticStyle: { width: "19px", display: "block" },
            attrs: { src: _vm.open ? _vm.closeImg : _vm.openImg, alt: "" },
            on: {
              click: function($event) {
                _vm.open = !_vm.open
              }
            }
          })
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.titles[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
            },
            [
              _c(
                "el-upload",
                _vm._b(
                  {
                    attrs: { slot: "drawing", "file-list": _vm.attachments },
                    slot: "drawing"
                  },
                  "el-upload",
                  _vm.uploadProps2,
                  false
                ),
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "small" } },
                    [_vm._v("上传附件")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.titles2[_vm.currentType] },
          on: { onOk: _vm.submitHotForm },
          model: {
            value: _vm.hotVisible,
            callback: function($$v) {
              _vm.hotVisible = $$v
            },
            expression: "hotVisible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild2",
              attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
            },
            [
              _c(
                "el-upload",
                _vm._b(
                  {
                    attrs: { slot: "drawing", "file-list": _vm.attachments },
                    slot: "drawing"
                  },
                  "el-upload",
                  _vm.uploadProps2,
                  false
                ),
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "small" } },
                    [_vm._v("上传附件")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { width: "400px", title: "新增检验组" },
          on: { onOk: _vm.submitGroupForm },
          model: {
            value: _vm.groupVisible,
            callback: function($$v) {
              _vm.groupVisible = $$v
            },
            expression: "groupVisible"
          }
        },
        [
          _c("el-input", {
            staticStyle: { "padding-right": "15px" },
            attrs: { placeholder: "请输入项目组名称" },
            model: {
              value: _vm.formData.name,
              callback: function($$v) {
                _vm.$set(_vm.formData, "name", $$v)
              },
              expression: "formData.name"
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "检验项详情", showFooter: false },
          model: {
            value: _vm.viewVisible,
            callback: function($$v) {
              _vm.viewVisible = $$v
            },
            expression: "viewVisible"
          }
        },
        [
          _vm.viewVisible
            ? _c(
                "el-row",
                { staticClass: "view-list", attrs: { gutter: 24 } },
                [
                  _vm._l(_vm.formListColumns, function(item, index) {
                    return [
                      item.prop !== "possibleValue" ||
                      _vm.formData.parameterType === 2 ||
                      _vm.formData.parameterType === 3
                        ? _c(
                            "el-col",
                            {
                              key: item.prop,
                              style: { marginBottom: index !== 4 ? "20px" : 0 },
                              attrs: { span: item.prop === "remark" ? 24 : 12 }
                            },
                            [
                              _c("span", { staticClass: "bot-tis" }, [
                                _vm._v(_vm._s(item.label) + "：")
                              ]),
                              _c("span", { staticClass: "bot-tis" }, [
                                _vm._v(_vm._s(_vm.getValue(item)))
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  }),
                  _vm.formData.attachments && _vm.formData.attachments.length
                    ? _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c("div", [_vm._v("附件：")]),
                          _c(
                            "div",
                            _vm._l(_vm.formData.attachments, function(
                              at,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: { "padding-bottom": "5px" }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#607FFF" },
                                      attrs: { href: at.url, target: "_blank" }
                                    },
                                    [_vm._v(_vm._s(at.name))]
                                  ),
                                  _c("img", {
                                    staticStyle: {
                                      width: "12px",
                                      "margin-left": "5px"
                                    },
                                    attrs: {
                                      src: require("@/assets/quality/路径@2x.png"),
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "title" }, [_vm._v("检验项")]),
      _c("i")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "title" }, [_vm._v("常用库")]),
      _c("i")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
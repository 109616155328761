<template>
  <div class="orderAdd">
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-form ref="ruleForm" label-position="right" :model="ruleForm" :rules="rules" label-width="120px" class="demo-form-inline">
          <el-row>
            <el-col :span="6">
              <el-form-item label="采购订单编号" prop="code">
                <el-input v-model="ruleForm.code" :maxlength="100" placeholder="请输入采购订单编号" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="供应商" prop="supplierId">
                <el-select v-model="ruleForm.supplierId" v-selectScrollEnd="supplierCandidatesLoadMore" filterable remote reserve-keyword :remote-method="remoteMethod" placeholder="请选择供应商">
                  <el-option
                    v-for="item in supplierOptions"
                    :key="item.id"
                    :label="item.fullName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">采购订单行</div>
      <div class="card-bot">
        <FunctionHeader
          ref="functionHeader"
          style="padding: 10px 0 10px 0;"
          :hidden-search="true"
        >
          <div slot="all">
            <el-button size="small" type="primary" class="e-btn" @click="selectAdd">添加物料</el-button>
          </div>
        </FunctionHeader>
        <MTable ref="mTable" only-key="uuid" :height="400" :show-page="false" :data="orderList" :columns="columnsOrder">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="purchaseQuantity" slot-scope="{ row }">
            <el-input-number v-model="row.purchaseQuantity" :min="0" controls-position="right" :step="0.01" :style="{border:row.isWarningPurchaseQuantity?'1px solid #F56C6C':'',borderRadius:'4px'}" @blur="e=>onBlur(e,row,'purchaseQuantity')" />
          </div>
          <div slot="wantReceiveTime" slot-scope="{ row }">
            <el-date-picker
              v-model="row.wantReceiveTime"
              :style="{border:row.isWarningWantReceiveTime?'1px solid #F56C6C':'',borderRadius:'4px'}"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
            />
          </div>
          <div slot="confirmReceiveTime" slot-scope="{ row }">
            <!-- :style="{border:row.isWarningConfirmReceiveTime?'1px solid #F56C6C':'',borderRadius:'4px'}" -->
            <el-date-picker
              v-model="row.confirmReceiveTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
            />
          </div>
          <div slot="remark" slot-scope="{ row }">
            <el-input v-model="row.remark" />
          </div>
          <div slot="action" slot-scope="{ row,$index }">
            <!--<el-button type="text" class="li-btn" @click="()=>onCopy(row,$index)">复制</el-button>-->
            <el-button type="text" class="li-btn" @click="()=>orderList.splice($index,1)">移除</el-button>
          </div>
        </MTable>
      </div>
    </div>
    <div class="order-footer">
      <div />
      <div class="footer-ri">
        <el-button @click="returnList">取消</el-button>
        <el-button type="primary" @click="save">确定</el-button>
      </div>
    </div>
    <select-materials
      ref="selectMaterials"
      :select-materials="selectMaterials"
      :visible.sync="showMaterials"
      :is-producible="false"
      :is-add="true"
      @submitForm="submitMaterials"
      @addMaterial="addMaterial"
    />
    <!-- 新增物料 -->
    <edit-materials ref="editMaterials" @onSuccess="onSuccess" />
  </div>
</template>
<script>
import FunctionHeader from '@/components/FunctionHeader'
import api from '@/api/production/purchaseManage'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials.vue'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import { columnsOrder } from './data'
const checkCode = (rule, val, cb) => {
  if (!val) {
    return cb(new Error('请输入采购订单编号'))
  }
  const reg = /^[^\u4e00-\u9fa5]+$/
  if (!reg.test(val)) {
    return cb(new Error('不能包含中文'))
  }
  cb()
}
export default {
  name: 'PurchaseOrderAdd',
  components: {
    EditMaterials,
    SelectMaterials,
    FunctionHeader
  },
  data() {
    return {
      columnsOrder,
      selectMaterials: [],
      orderList: [],
      showMaterials: false,
      supplierOptions: [],
      ruleForm: {
        code: '',
        supplierId: ''
      },
      rules: {
        code: [
          { validator: checkCode, trigger: 'blur' }
        ],
        supplierId: [
          { required: true, message: '请选择供应商', trigger: 'blur' }
        ]
      },
      supplierCandidatesParams: {
        page: 1,
        size: 10,
        search: ''
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    onCopy(row, idx) {
      const newRow = this._.cloneDeep(row)
      newRow.purchaseQuantity = 0
      newRow.wantReceiveTime = ''
      newRow.confirmReceiveTime = ''
      delete newRow.id
      this.orderList.splice(idx + 1, 0, newRow)
    },
    async init() {
      this.type = this.$route.query.type
      this.getSupplierCandidates(this.supplierCandidatesParams)
      this.dataString = JSON.stringify(this.orderList) + JSON.stringify(this.ruleForm)
      if (this.type === 'edit') this.getDetail()
    },

    onBlur(e, row, key, num = 5) {
      const valStr = e.target.value + ''
      const decimalLength = valStr.split('.')[1]?.length
      if (decimalLength > num) {
        e.target.value = valStr.slice(0, valStr.length - (decimalLength - num)) * 1
        row[key] = e.target.value
      }
    },

    onDel(idx) {
      this.orderList.splice(idx, 1)
    },

    selectAdd() {
      this.selectMaterials = this._.cloneDeep(this.orderList)
      this.selectMaterials.forEach(item => { item.id = item.materialsId })
      this.showMaterials = true
    },

    onSuccess() {
      this.$refs.selectMaterials.updateTable()
    },

    submitMaterials(val) {
      if (!val) return
      const materialsIds = this.orderList.map(item => item.materialsId ? item.materialsId : item.id)
      val.forEach(item => {
        if (!materialsIds.includes(item.id)) {
          item.materialsId = item.id
          delete item.id
          this.$set(item, 'purchaseQuantity', 0)
          this.orderList.push(item)
        }
      })
      this.showMaterials = false
    },

    addMaterial() {
      this.$refs.editMaterials.add()
    },

    // 获取详情
    async getDetail() {
      const res = await api.viewDetail({ id: this.$route.query.id })
      if (res) {
        const { code, supplierId, orderItemList } = res
        this.orderList = orderItemList || []
        this.ruleForm = {
          code,
          supplierId
        }
        this.dataString = JSON.stringify(this.orderList) + JSON.stringify(this.ruleForm)
      }
    },

    // 成品物料下拉触底分页加载
    supplierCandidatesLoadMore() {
      if (this.supplierOptions.length === this.supplierCandidatesTotal) return
      this.supplierCandidatesParams.page++
      this.getSupplierCandidates(this.supplierCandidatesParams)
    },

    remoteMethod(val) {
      this.supplierOptions.length = 0
      this.supplierCandidatesParams = {
        page: 1,
        size: 10,
        search: val
      }
      this.getSupplierCandidates(this.supplierCandidatesParams)
    },

    // 获取供应商下拉
    async getSupplierCandidates(params) {
      const res = await api.supplierCandidates(params)
      if (res) {
        this.supplierCandidatesTotal = res.total
        this.supplierOptions.push(...res.records || [])
      }
    },

    async save() {
      const flag1 = await this.$refs.ruleForm.validate()
      if (!this.orderList.length) return this.$message.warning('请添加物料')
      let flagPurchaseQuantity = 0
      let flagWantReceiveTime = 0
      // let flagConfirmReceiveTime = 0
      this.orderList.forEach(item => {
        this.$set(item, 'isWarningPurchaseQuantity', !item.purchaseQuantity)
        this.$set(item, 'isWarningWantReceiveTime', !item.wantReceiveTime)
        // this.$set(item, 'isWarningConfirmReceiveTime', !item.confirmReceiveTime)
        if (!item.purchaseQuantity) flagPurchaseQuantity++
        if (!item.wantReceiveTime) flagWantReceiveTime++
        // if (!item.confirmReceiveTime) flagConfirmReceiveTime++
      })
      if (flagPurchaseQuantity) return this.$message.warning('请填写采购数量')
      if (flagWantReceiveTime) return this.$message.warning('请选择合同到货时间')
      // if (flagConfirmReceiveTime) return this.$message.warning('请选择供应商确认到货时间')
      if (flag1) {
        const apis = {
          add: api.addPurchaseOrder,
          edit: api.updatePurchaseOrder
        }
        const type = this.$route.query.type
        const params = this.formateParm(type)
        const res = await apis[type](params)
        if (res) {
          this.$message.success('操作成功')
          this.$router.go(-1)
        }
      }
    },

    formateParm(type) {
      const orderItemList = this.orderList.map(item => {
        const { id, materialsId, purchaseQuantity, wantReceiveTime, confirmReceiveTime } = item
        const newItem = { materialsId, purchaseQuantity, wantReceiveTime, confirmReceiveTime }
        id && (newItem.id = id)
        return newItem
      })
      this.ruleForm.orderItemList = orderItemList
      type === 'edit' && (this.ruleForm.id = this.$route.query.id)
      return this.ruleForm
    },

    returnList() {
      if (this.dataString === JSON.stringify(this.orderList) + JSON.stringify(this.ruleForm)) return this.$router.go(-1)
      this.$confirm('当前页有内容未保存，是否保存后离开?', '提示', {
        confirmButtonText: '保存',
        cancelButtonText: '直接离开',
        type: 'warning'
      }).then(() => {
        this.save()
      }).catch(() =>
        this.$router.go(-1))
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-upload-list__item.is-success.focusing .el-icon-close-tip {
  display: none !important;
}
::v-deep {
  .el-tag {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .el-form-item {
    margin-bottom: 22px;
  }
  .el-date-editor .el-range-separator,
  .el-date-editor .el-range__icon {
    line-height: 26px;
  }
  .el-input-number {
    height: 32px;
    line-height: 32px;
    width: 100%;
  }
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-input-number.is-controls-right .el-input-number__increase,
  .el-input-number.is-controls-right .el-input-number__decrease {
    line-height: 15px;
  }
  .el-tag .el-icon-close {
    position: absolute;
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 16px;
    vertical-align: middle;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
}
.el-tags {
  padding: 0 20px 0 10px;
  position: relative;
}
.orderAdd {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  .input-new-tag {
    width: 130px;
  }
  .button-new-tag {
    height: 32px;
  }
  .detail-card {
    margin-bottom: 20px;
  }
  .lines {
    width: 100%;
    height: 20px;
    background: #f0f2f5;
  }
  ::v-deep .el-select {
    width: 100%;
  }
  .e-btn {
    margin-bottom: 10px;
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.bot-li {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  .li-tit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 700;
    color: #606266;
    line-height: 32px;
    width: 95px;
    padding-right: 12px;
    text-align: right;
  }
  .li-des {
    position: relative;
    .more {
      // margin: 0 0 0 110px;
      cursor: pointer;
      width: 45px;
      height: 20px;
      background: #ebeef5;
      text-align: center;
      border-radius: 5px;
      margin: 10px auto;
      line-height: 20px;
      position: absolute;
      top: 32px;
      right: -60px;
    }
  }
}

.procedure-detail-header {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #41456b;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  img {
    width: 8px;
    height: 12px;
    display: block;
    margin-right: 10px;
  }
}
</style>
